import React from 'react'
import { Link } from 'gatsby'
import SEO from '../components/seo'
import Layout from '../components/Layout'
import {
    subpageHero,
    pricingSection,
    pricingColumn,
    pricingHeader,
} from './features.module.scss'

import GetStartedSection from '../components/GetStartedSection'


const Pricing = () => {
    return (
        <>
            <SEO
                title="Áskriftarleiðir - Tilkynna"
                description="Áskriftarleiðir - Tilkynna"
            />
            <Layout isFrontpage>
                <div>
                    <section
                        className={subpageHero}
                    >
                        <div>
                            <div>
                                <h1>Áskriftarleiðir</h1>
                                <h2>
                                    Þú getur prófað allar áskriftarleiðir frítt í 30 daga.
                                </h2>
                                <h3>Veldu áskriftarleið sem hentar þinni starfsemi.</h3>

                            </div>
                        </div>
                    </section>


                    <section
                        className={pricingSection}
                    >
                        <div>
                            <div className={pricingColumn}>
                                <div>
                                    <div className={pricingHeader}>
                                        <h3>Grunnur</h3>
                                        <p><span>15.900 kr.</span> á mánuði (+vsk)</p>
                                        <p><small><i>Greitt árlega</i></small></p>
                                        <Link
                                            className="btn"
                                            to={`/is/signup/`}
                                        >
                                            Prófa frítt í 30 daga
                                        </Link>
                                    </div>
                                    <ul>
                                        <li>
                                            Nafnlaus samskipti
                                        </li>
                                        <li>
                                            Auðkenning með Ísland.is
                                        </li>
                                        <li>
                                            Tilkynningasvæði: 1
                                        </li>
                                        <li>
                                            Síður: 3
                                        </li>
                                        <li>
                                            Notendur: 3
                                        </li>
                                        <li>
                                            Gagnageymsla: 20 GB
                                        </li>
                                        <li>
                                            Athugasemdakerfi
                                        </li>
                                        <li>
                                            Tölfræði
                                        </li>
                                        <li>
                                            Stuðningur við uppsetningu
                                        </li>
                                    </ul>
                                    {/* <div className={priceInfoBottom}>
                                        <p>* <span>190.800 kr.</span> á ári (+vsk)</p>
                                        <p><i>Greitt árlega fyrirfram</i></p>
                                    </div> */}
                                </div>
                            </div>

                            <div className={pricingColumn}>
                                <div>
                                    <div className={pricingHeader}>
                                        <h3>Vöxtur</h3>
                                        <p><span>24.900 kr.</span> á mánuði (+vsk)</p>
                                        <p><small><i>Greitt árlega</i></small></p>
                                        <Link
                                            className="btn"
                                            to={`/is/signup/`}
                                        >
                                            Prófa frítt í 30 daga
                                        </Link>
                                    </div>
                                    <ul>
                                        <li>
                                            Nafnlaus samskipti
                                        </li>
                                        <li>
                                            Auðkenning með Ísland.is
                                        </li>
                                        <li>
                                            Tilkynningasvæði: 3
                                        </li>
                                        <li>
                                            Síður: 6
                                        </li>
                                        <li>
                                            Notendur: 6
                                        </li>
                                        <li>
                                            Gagnageymsla: 40 GB
                                        </li>
                                        <li>
                                            Athugasemdakerfi
                                        </li>
                                        <li>
                                            Tölfræði
                                        </li>
                                        <li>
                                            Stuðningur við uppsetningu
                                        </li>
                                        <li>
                                            Lykilorð á tilkynningasvæði
                                        </li>
                                        <li>
                                            Embedda tilkynningasvæði
                                        </li>
                                        <li>
                                            Breytilegar spurningar
                                        </li>
                                        <li>
                                            Boðskerfi fyrir tilkynningar
                                        </li>

                                    </ul>
                                </div>
                            </div>

                            <div className={pricingColumn}>
                                <div>
                                    <div className={pricingHeader}>
                                        <h3>Sérsniðinn</h3>
                                        <p><span>Hafðu samband</span></p>
                                        <p>til að fá tilboð</p>
                                        <a
                                            className="btn"
                                            href="mailto:info@tilkynna.is"
                                        >
                                            Hafðu samband
                                        </a>
                                    </div>
                                    <ul>
                                        <li>
                                            Nafnlaus samskipti
                                        </li>
                                        <li>
                                            Auðkenning með Ísland.is
                                        </li>
                                        <li>
                                            Tilkynningasvæði: <i>Sveigjanlegt</i>
                                        </li>
                                        <li>
                                            Síður: <i>Sveigjanlegt</i>
                                        </li>
                                        <li>
                                            Notendur: <i>Sveigjanlegt</i>
                                        </li>
                                        <li>
                                            Gagnageymsla: <i>Sveigjanlegt</i>
                                        </li>
                                        <li>
                                            Athugasemdakerfi
                                        </li>
                                        <li>
                                            Tölfræði
                                        </li>
                                        <li>
                                            Stuðningur við uppsetningu
                                        </li>
                                        <li>
                                            Lykilorð á tilkynningasvæði
                                        </li>
                                        <li>
                                            Embedda tilkynningasvæði
                                        </li>
                                        <li>
                                            Breytilegar spurningar
                                        </li>
                                        <li>
                                            Boðskerfi fyrir tilkynningar
                                        </li>
                                        <li>
                                            Sérsniðnir eiginleikar
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </section>
                    <GetStartedSection
                        backgroundColor="#eee"
                    />

                </div>
            </Layout>
        </>
    )
}

export default Pricing

